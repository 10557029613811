import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import Swal from 'sweetalert2';
import moment from 'moment'
// apicore
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

// helpers
import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
} from '../../helpers/';

// actions
import { authApiResponseSuccess, authApiResponseError } from './actions';

// constants
import { AuthActionTypes } from './constants';

type UserData = {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
    };
    type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
// ---------------------- latest code --------------------------------

// function* login({ payload: { email, password } }: UserData): SagaIterator {
//     try {
//         const user = yield call(loginApi, { email, password });
//         console.log('API Response:', user); // Log the API response

//         // Check if the user object is valid
//         if (!user || typeof user !== 'object' || !user.id) {
//             yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, 'User data not found.'));
//             return;
//         }

//         // Check if the user's account is restricted
//         const toDate = moment(user.todate, "YYYY-MM-DD HH:mm:ss");
//         if (user.restrict === 1) {
//             yield call(() => 
//                 Swal.fire({
//                     title: 'Alert!',
//                     text:  user.alerttext,
//                     icon: 'warning',
//                     confirmButtonText: 'OK',
//                     allowOutsideClick: false,
//                 })
//             );
//             api.setLoggedInUser(null);
//             setAuthorization(null, 0);
//             yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, 'Access Denied'));
//             return;
//         }

//         // Check for login alerts
//         if (user.loginalert === 1 && moment().isSameOrBefore(moment(toDate))) {
//             yield call(() =>
//                 Swal.fire({
//                     title: 'Alert!',
//                     text: user.alerttext,
//                     icon: 'warning',
//                     confirmButtonText: 'OK',
//                     allowOutsideClick: false,
//                 })
//             );
//         }

//         // Set the user as logged in
//         api.setLoggedInUser(user);
//         setAuthorization(user.token, user.activeBranch);
//         yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));

//     } catch (error: any) {
//         console.error('Error during login:', error);
//         yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
//         api.setLoggedInUser(null);
//         setAuthorization(null, 0);
//     }
// }
function* login({ payload: { email, password } }: UserData): SagaIterator {
    try {
        const user = yield call(loginApi, { email, password });
        console.log('API Response:', user); // Log the API response

        // Check if the user object is valid
        if (!user || typeof user !== 'object' || !user.id) {
            yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, 'User data not found.'));
            return;
        }

        // Check if the user's account is restricted
        const toDate = moment(user.todate, "YYYY-MM-DD HH:mm:ss");
        if (user.restrict === 1) {
            yield call(() => 
                Swal.fire({
                    title: 'Alert!',
                    text:  user.alerttext,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                })
            );
            api.setLoggedInUser(null);
            setAuthorization(null, 0);
            yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, 'Access Denied'));
            return;
        }

        // Check for login alerts
        if (user.loginalert === 1 && moment().isSameOrBefore(moment(toDate))) {
            yield call(() =>
                Swal.fire({
                    title: 'Alert!',
                    text: user.alerttext,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                })
            );
        }

        // Set the user as logged in
        api.setLoggedInUser(user);
        setAuthorization(user.token, user.activeBranch);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));

    } catch (error: any) {
        console.log('Saga caught raw error:', error);
    
        const apiErrorMsg =
            error?.response?.data?.error || // This will now catch 401 API error message
            error?.error || 
            'An unexpected error occurred.';
    
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, apiErrorMsg));
    }
    
}







/**
 * Logout the user
 */
// function* logout(): SagaIterator {
//     try {
//         // yield call(logoutApi);
//         api.setLoggedInUser(null);
//         setAuthorization(null,0);
//          sessionStorage.removeItem('user_Information');
//          sessionStorage.removeItem('companyInfo');
//          sessionStorage.removeItem('branch');
//         yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
//     } catch (error: any) {
//         yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
//     }
// }

// function* logout(): SagaIterator {
//     try {
//         yield call(logoutApi);
//         api.setLoggedInUser(null);
//         setAuthorization(null,0);
//          sessionStorage.removeItem('user_Information');
//          sessionStorage.removeItem('companyInfo');
//          sessionStorage.removeItem('branch');
//         yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
//     } catch (error: any) {
//         yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
//     }
// }
function* logout(): SagaIterator {
    try {
        console.log("Calling logoutApi...");
        yield call(logoutApi);
        console.log("logoutApi completed successfully.");
        api.setLoggedInUser(null);
        setAuthorization(null, 0);
        sessionStorage.removeItem('user_Information');
        sessionStorage.removeItem('companyInfo');
        sessionStorage.removeItem('branch');
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        console.error("Error during logout:", error);
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { fullname, email, password } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, { fullname, email, password });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null,0);
    }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { email });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}
export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword)]);
}

export default authSaga;
