// constants
import { EmployeeActionTypes } from './constants';

export type EmployeeActionType = {
    type:
        | EmployeeActionTypes.API_RESPONSE_SUCCESS
        | EmployeeActionTypes.API_RESPONSE_ERROR
        | EmployeeActionTypes.RESET
        | EmployeeActionTypes.ADD_EM
        | EmployeeActionTypes.UPD_EM
        | EmployeeActionTypes.ADD_DESIG
        | EmployeeActionTypes.ADD_DEPART
        | EmployeeActionTypes.ADD_JOBTYPE
        | EmployeeActionTypes.ADD_ASSET
        | EmployeeActionTypes.ADD_QUALI
        | EmployeeActionTypes.ADD_GRADES
        | EmployeeActionTypes.ADD_SHIFT
        | EmployeeActionTypes.ADD_LEAVE
        | EmployeeActionTypes.ADD_APPLICATION
        | EmployeeActionTypes.UPD_HOLIDAY
        | EmployeeActionTypes.ADD_ATTENDANCE
        | EmployeeActionTypes.ADD_NATI        
        | EmployeeActionTypes.ADD_ALLOWANCETYPE
        | EmployeeActionTypes.ADD_BONUS
        | EmployeeActionTypes.ADD_GROUPS
        | EmployeeActionTypes.ADD_OCCU
        | EmployeeActionTypes.ADD_RELA
        | EmployeeActionTypes.ADD_WORK
        | EmployeeActionTypes.ADD_DOC
        | EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE
    payload: {} | string;
};

type EmpData = {
     cardno:string;empname:string;fname:string;dateofbirth:string;gender:string;cnic:string;mainaddress:string;cityid:string;martialStatus:string;religionid:string;phone:string;alterPhone:string;email:string;contactperson:string;phone2:string;address:string;joindate:string;jobtypeid:string;departmentid:string;designationid:string;salary:string;cleave:string;sickleave:string;annualleave:string;startdate:string;enddate:string;description:string;incrementPer:string;inctype:string;workingdays:string;timefrom:string;timeto:string;codeofconduct:string;shiftid:object;bonusgrid:object;assetsgrid:object;backgroundgrid:object;documentgrid:object;
};

// common success
export const employeeApiResponseSuccess = (actionType: string, data: EmpData | {}): EmployeeActionType => ({
    type: EmployeeActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const employeeApiResponseError = (actionType: string, error: string): EmployeeActionType => ({
    type: EmployeeActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addEmp = (formdata:object,bonusgrid:object,assetsgrid:object,backgroundgrid:object,documentgrid:object,dependsgrid:object,jobperiodgrid:object,incrementgrid:object, educationalgrid:object, allowancegrid:object): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_EM,
    payload: {formdata,bonusgrid,assetsgrid,backgroundgrid,documentgrid,dependsgrid,jobperiodgrid,incrementgrid, educationalgrid, allowancegrid},
});
export const updEmp = (id:string,formdata:object,bonusgrid:object,assetsgrid:object,backgroundgrid:object,documentgrid:object,dependsgrid:object,jobperiodgrid:object,incrementgrid:object, educationalgrid:object, allowancegrid:object): EmployeeActionType => ({
    type: EmployeeActionTypes.UPD_EM,
    payload: {id,formdata,bonusgrid,assetsgrid,backgroundgrid,documentgrid,dependsgrid,jobperiodgrid,incrementgrid, educationalgrid, allowancegrid},
});

export const addNationality = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_NATI,
    payload: {name},
});
export const addDesignation = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_DESIG,
    payload: {name},
});
export const addDepartment = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_DEPART,
    payload: {name},
});
export const addJobType = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_JOBTYPE,
    payload: {name},
});
export const addGrades = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_GRADES,
    payload: {name},
});
export const addshift = (name:string,timein:string,timeout:string,inbefore:string,inafter:string,outbefore:string,outafter:string,applydate:string,status:string,departmentid:string,isdefault:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_SHIFT,
    payload: {name,timein,timeout,inbefore,inafter,outbefore,outafter,applydate,status,departmentid,isdefault},
});
export const addleave = (departmentwise:string,leavesGrid:object,): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_LEAVE,
    payload: {departmentwise,leavesGrid},
});
export const addapplication = (apptype:string,from:string,to:object,headline:string,body:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_APPLICATION,
    payload: {apptype,from,to,headline,body},
});

export const updholiday = (departmentwiseweekly:string,departmentwisespecial:string,weeklyGrid:object,gazetteGrid:object,specialGrid:object,): EmployeeActionType => ({
    type: EmployeeActionTypes.UPD_HOLIDAY,
    payload: {departmentwiseweekly,departmentwisespecial,weeklyGrid,gazetteGrid,specialGrid,},
});
export const addattendance = (attendancedata:object): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_ATTENDANCE,
    payload: {attendancedata},
});
export const addAssettype = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_ASSET,
    payload: {name},
});
export const addRela = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_RELA,
    payload: {name},
});
export const addAllowancetype = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_ALLOWANCETYPE,
    payload: {name},
});
export const addBonustype = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_BONUS,
    payload: {name},
});
export const addGroups = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_GROUPS,
    payload: {name},
});
export const addOccu = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_OCCU,
    payload: {name},
});
export const addWorkplaces = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_WORK,
    payload: {name},
});
export const addDoctype = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_DOC,
    payload: {name},
});
export const resetEmpolyee = (): EmployeeActionType => ({
    type: EmployeeActionTypes.RESET,
    payload: {},
});

export const addQualification = (name:string): EmployeeActionType => ({
    type: EmployeeActionTypes.ADD_QUALI,
    payload: {name},
});

export const addEmployeeBalance = (entrydate:string,rowisedata:Object)=>({
    type:EmployeeActionTypes.OPEN_EMPLOYEE_BALANCE,
    payload:{entrydate,rowisedata}
})

